import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Logo from '../../components/header/Logo';
import NavMenu from '../../components/header/NavMenu';
import MobileMenu from '../../components/header/MobileMenu';
import IconGroup from '../../components/header/IconGroup';

const HeaderSMLabs = ({
	layout,
	top,
	borderStyle,
	headerPaddingClass,
	headerPositionClass,
	headerBgClass,
}) => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		setScroll(window.scrollY);
	};

	return (
		<header
			className={`header-area clearfix ${headerBgClass ? headerBgClass : ''} ${
				headerPositionClass ? headerPositionClass : ''
			}`}
		>
			<div
				className={`${headerPaddingClass ? headerPaddingClass : ''} ${
					top === 'visible' ? 'd-none d-lg-block' : 'd-none'
				} header-top-area ${borderStyle === 'fluid-border' ? 'border-none' : ''}`}
			></div>

			<div
				className={` ${headerPaddingClass ? headerPaddingClass : ''} header-res-padding clearfix `}
			>
				<div className='container-full'>
					<div className='top-bar'>
						<div className='top-bar-item'>
							<p style={{fontSize: '15px', fontWeight: '500'}}>
								Call Us <a href='tel:01749684085'>+44 (0)1749 684085</a>
							</p>
						</div>

						<div className='header-offer'>
							<p style={{fontSize: '15px', fontWeight: '500'}}>
								Lateral flow tests bulk prices from only <span>from only 92p</span> - Contact us
							</p>
						</div>
					</div>
				</div>

				<div className={layout === 'container-fluid' ? layout : 'container'}>
					<div className='row'>
						<div className='col-xl-2 col-lg-2 col-md-6 col-4'>
							<Logo
								imageUrl='https://testkitexpress.co.uk/wp-content/uploads/2022/01/tke-wp-header-logo.png'
								logoClass='custom-logo-link'
							/>
						</div>
						<div className='col-xl-8 col-lg-8 d-none d-lg-block'>
							<NavMenu />
						</div>
						<div className='col-xl-2 col-lg-2 col-md-6 col-8'>
							<IconGroup />
						</div>
					</div>
				</div>
				<MobileMenu />
			</div>
		</header>
	);
};

HeaderSMLabs.propTypes = {
	borderStyle: PropTypes.string,
	headerPaddingClass: PropTypes.string,
	headerPositionClass: PropTypes.string,
	layout: PropTypes.string,
	top: PropTypes.string,
};

export default HeaderSMLabs;
