import PropTypes from 'prop-types';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {ErrorMessage, Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import {CountryDropdown} from 'react-country-region-selector';
import {renderError} from '../../helpers/testKitOptions';
import SectionTitle from '../../components/section-title/SectionTitle';
import AllFormError from '../../components/booking-form/AllFormError';
import ProgressBarSteps from '../../components/booking-form/ProgressBarSteps';

const LFShippingAddress = ({onClickFormNext, category, initialFormValues}) => {
	const history = useHistory();

	if (initialFormValues.personal.email === '') {
		history.push('/lateral-flow-form');
	}

	return (
		<div>
			<Formik
				initialValues={initialFormValues !== null ? initialFormValues['personal'] : {}}
				validationSchema={Yup.object({
					hotelName: Yup.string().when('residence', {
						is: residence => residence === 'hotel',
						then: Yup.string().required('Please enter the name of your hotel'),
						otherwise: Yup.string(),
					}),
					addressLine1: Yup.string().required('Please enter the first line of your address'),
					city: Yup.string().required('Please enter your city'),
					postCode: Yup.string().required('Please enter your postcode'),
					billingLine1: Yup.string().when('billingAddress', {
						is: address => address === 'useDifferent',
						then: Yup.string().required('Please enter the first line of your billing address'),
						otherwise: Yup.string(),
					}),
					billingCity: Yup.string().when('billingAddress', {
						is: address => address === 'useDifferent',
						then: Yup.string().required('Please enter your billing address city'),
						otherwise: Yup.string(),
					}),
					billingPostCode: Yup.string().when('billingAddress', {
						is: address => address === 'useDifferent',
						then: Yup.string().required('Please enter your billing address postcode'),
						otherwise: Yup.string(),
					}),
				})}
				onSubmit={(values, {setSubmitting}) => {
					setSubmitting(false);
					onClickFormNext(category, values);
				}}
			>
				{props => (
					<Form>
						<SectionTitle titleText='Shipping Address' positionClass='text-center' />
						<div id='residence-group' className='radioTitle'>
							Where should we send the kit to?
						</div>
						<div role='group' aria-labelledby='residence-group'>
							<label className='radiocontainer'>
								To My Home
								<Field type='radio' name='residence' value='home' />
								<span className='customradio'></span>
							</label>
							<label className='radiocontainer'>
								To My Hotel
								<Field type='radio' name='residence' value='hotel' />
								<span className='customradio'></span>
							</label>
						</div>
						{props.values.residence === 'hotel' && (
							<>
								<label htmlFor='hotelName'>Name of Hotel </label>{' '}
								<span className='required'>*</span>
								<Field name='hotelName' type='text' />
								<ErrorMessage name='hotelName' render={renderError} />
							</>
						)}
						<label htmlFor='addressLine1'>Address Line 1</label> <span className='required'>*</span>
						<Field name='addressLine1' type='text' />
						<ErrorMessage name='addressLine1' render={renderError} />
						<label htmlFor='addressLine2'>Address Line 2</label>{' '}
						<Field name='addressLine2' type='text' />
						<ErrorMessage name='addressLine2' render={renderError} />
						<label htmlFor='addressLine3'>Address Line 3</label>{' '}
						<Field name='addressLine3' type='text' />
						<ErrorMessage name='addressLine3' render={renderError} />
						<label htmlFor='city'>City</label> <span className='required'>*</span>
						<Field name='city' type='text' />
						<ErrorMessage name='city' render={renderError} />
						<label htmlFor='postCode'>Postcode</label> <span className='required'>*</span>
						<Field name='postCode' type='text' />
						<ErrorMessage name='postCode' render={renderError} />
						<label htmlFor='country'>Country</label> <span className='required'>*</span>
						<CountryDropdown
							name='country'
							value={props.values.country}
							onChange={(_, e) => props.handleChange(e)}
							priorityOptions={['GB']}
							className='custom-select'
							// valueType='short'
						/>
						<ErrorMessage name='country' render={renderError} />
						<SectionTitle
							titleText='Billing Address'
							positionClass='text-center'
							spaceClass='pt-25'
						/>
						<div id='residence-group' className='radioTitle'>
							Select the address that matches your card or payment method.
						</div>
						<div role='group' aria-labelledby='residence-group'>
							<label className='radiocontainer'>
								Same as shipping address
								<Field type='radio' name='billingAddress' value='sameAsShipping' />
								<span className='customradio'></span>
							</label>
							<label className='radiocontainer'>
								Use a different billing address
								<Field type='radio' name='billingAddress' value='useDifferent' />
								<span className='customradio'></span>
							</label>
						</div>
						{props.values.billingAddress === 'useDifferent' && (
							<>
								<label htmlFor='billingLine1'>Address Line 1</label>{' '}
								<span className='required'>*</span>
								<Field name='billingLine1' type='text' />
								<ErrorMessage name='billingLine1' render={renderError} />
								<label htmlFor='billingLine2'>Address Line 2</label>{' '}
								<Field name='addressLine2' type='text' />
								<ErrorMessage name='billingLine2' render={renderError} />
								<label htmlFor='billingCity'>City</label> <span className='required'>*</span>
								<Field name='billingCity' type='text' />
								<ErrorMessage name='billingCity' render={renderError} />
								<label htmlFor='billingPostCode'>Postcode</label>{' '}
								<span className='required'>*</span>
								<Field name='billingPostCode' type='text' />
								<ErrorMessage name='billingPostCode' render={renderError} />
								<label htmlFor='billingCountry'>Country</label> <span className='required'>*</span>
								<CountryDropdown
									name='billingCountry'
									value={props.values.billingCountry}
									onChange={(_, e) => props.handleChange(e)}
									priorityOptions={['GB']}
									className='custom-select'
									// valueType='short'
								/>
								<ErrorMessage name='billingCountry' render={renderError} />
							</>
						)}
						<AllFormError touched={props.touched} errors={props.errors} />
						<div className='doubleBtns'>
							<Link to={process.env.PUBLIC_URL + '/lateral-flow-form'} className='next-btn'>
								Back
							</Link>
							<button type='submit' className='next-btn'>
								Next
							</button>
						</div>
						<ProgressBarSteps percentile={25} numSteps={5} />
					</Form>
				)}
			</Formik>
		</div>
	);
};

LFShippingAddress.propTypes = {
	onClickFormNext: PropTypes.func,
	category: PropTypes.string,
	initialFormValues: PropTypes.object,
};

export default LFShippingAddress;
