import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import countryList from 'react-select-country-list';
import SectionTitle from '../section-title/SectionTitle';
import {
	getLatFlowPrice,
	getLateralFlowSuffix,
	parseLateralFlowTests,
} from '../../helpers/testKitOptions';
import ProgressBarSteps from './ProgressBarSteps';

const LFCheckoutForm = ({formValues, clientSecret, handleStripeOrder, prices}) => {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async e => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setIsLoading(true);

		let name = formValues.personal.firstName + ' ' + formValues.personal.lastName;
		let email = formValues.personal.email;
		let phone = formValues.personal.mobile;
		let line1 = formValues.personal.addressLine1;
		let line2 = formValues.personal.addressLine2;
		let city = formValues.personal.city;
		let postCode = formValues.personal.postCode;
		let country = countryList().getValue(formValues.personal.country);

		if (formValues.personal.billingAddress === 'useDifferent') {
			line1 = formValues.personal.billingLine1;
			line2 = formValues.personal.billingLine2;
			city = formValues.personal.billingCity;
			postCode = formValues.personal.billingPostCode;
			country = countryList().getValue(formValues.personal.billingCountry);
		}

		const res = await stripe
			.confirmCardPayment(clientSecret, {
				payment_method: {
					card: elements.getElement(CardElement),
					billing_details: {
						name: name,
						email: email,
						phone: phone,
						address: {
							line1: line1,
							line2: line2,
							city: city,
							country: country,
							postal_code: postCode,
						},
					},
				},
			})
			.then(function (result) {
				setIsLoading(false);

				if (result.error) {
					setMessage(result.error.message);
				} else {
					if (result.paymentIntent.status === 'succeeded') {
						const payload = {
							tests: parseLateralFlowTests(formValues.personal.tests),
							quantity: formValues.personal.quantity,
							firstName: formValues.personal.firstName,
							lastName: formValues.personal.lastName,
							email: formValues.personal.email,
							mobile: formValues.personal.mobile,
							residence: formValues.personal.residence,
							hotelName: formValues.personal.hotelName,
							addressLine1: formValues.personal.addressLine1,
							addressLine2: formValues.personal.addressLine2,
							addressLine3: formValues.personal.addressLine3,
							city: formValues.personal.city,
							postCode: formValues.personal.postCode,
							country: formValues.personal.country,
							billingAddress: formValues.personal.billingAddress,
							billingLine1: formValues.personal.billingLine1,
							billingLine2: formValues.personal.billingLine2,
							billingCity: formValues.personal.billingCity,
							billingPostCode: formValues.personal.billingPostCode,
							billingCountry: formValues.personal.billingCountry,
							price: getLatFlowPrice(formValues.personal, prices, '', true),
						};

						axios
							.post(
								'https://booking.testkitexpress.co.uk/booking/createLatFlowBooking.php',
								payload,
							)
							.then(function (response) {
								// console.log('response', response);

								const orderNumber = response.data;
								handleStripeOrder(orderNumber);
							})
							.catch(function (error) {
								console.log(error);
							});
					}
				}
			});
	};

	return (
		<>
			<SectionTitle titleText='Your Order' positionClass='text-center' spaceClass='pb-25' />

			<div className='row'>
				<div className='col-lg-12'>
					<div className='review-box'>
						<h4>Order Details</h4>
						<NumberFormat
							displayType='text'
							thousandSeparator={true}
							prefix='Test Kits: '
							suffix={getLateralFlowSuffix(formValues.personal.tests, formValues.personal.quantity)}
							value={formValues.personal.quantity}
						/>
						<br />
						<br />
						<h3>
							<strong>{getLatFlowPrice(formValues.personal, prices, 'Order Total: £')}</strong>
						</h3>
					</div>
				</div>
			</div>

			<div className='row stripe-checkout pt-10'>
				<div className='col-lg-12'>
					<form
						id='payment-form'
						onSubmit={handleSubmit}
						style={{
							display: 'block',
							width: '100%',
						}}
					>
						<div className='review-box'>
							<h4>Enter Your Card Details</h4>
							<CardElement
								className='card'
								options={{
									hidePostalCode: true,
									style: {
										base: {
											backgroundColor: 'white',
										},
									},
								}}
							/>
							<button
								className='payButton ml-auto mr-auto'
								disabled={isLoading || !stripe || !elements}
								id='submit'
							>
								{isLoading ? <div className='spinner' id='spinner'></div> : 'Pay Now'}
							</button>
							{message && <div id='payment-message'>{message}</div>}
						</div>
					</form>
					<ProgressBarSteps percentile={75} numSteps={5} />
				</div>
			</div>
		</>
	);
};

LFCheckoutForm.propTypes = {
	clientSecret: PropTypes.string,
	handleStripeOrder: PropTypes.func,
	formValues: PropTypes.object,
	prices: PropTypes.object.isRequired,
};
export default LFCheckoutForm;
