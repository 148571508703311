import {Fragment} from 'react';
import NumberFormat from 'react-number-format';
import {format} from 'date-fns';

export const clickCollect = 'Click & Collect';
export const inclinic = 'In Clinic';
export const renderError = message => <p className='formerror'>{message}</p>;

export const leaving = ['Fit2Fly'];
export const leavingLabels = ['Fit2Fly'];

export const arriving = ['Day2', 'Day5', 'Day8'];
export const arrivingLabels = ['Day 2', 'Day 5', 'Day 8'];

export const leavingArriving = ['Fit2Fly+Day2', 'Day8'];
export const leavingArrivingLabels = ['Fit2Fly + Day 2', 'Day 8'];

export const general = ['General'];
export const generalLabels = ['General Testing'];

export const homeCopy = (
	<p>
		<strong>Home test</strong> (posted to home or hotel) for you to post the test (return envelope
		provided) or drop off at our clinic.{' '}
	</p>
);

export const clickCollectCopy = (
	<p>
		<strong>{clickCollect}</strong> from our clinic then drop back sample.
	</p>
);

export const inClinicCopy = (
	<p>
		<strong>{inclinic}</strong> test at our clinic.
	</p>
);

export const lateralFlowCopy = (
	<p>
		<strong>Lateral Flow</strong> tests are posted to you for testing at home.
	</p>
);

export const lookupPrice = (kitName, prices) => {
	return prices[kitName];
};

export const getPrice = (vals, prices, prefix, noNumberFormat) => {
	let price = 0;
	let tests = vals.tests;

	if (tests.length > 1) {
		tests.sort();
		tests = tests.join('_');
	}
	const options = vals.options === false ? 'Posted_Next' : vals.options;
	const kitWithOptions = `${tests}_${options}`;
	price += lookupPrice(kitWithOptions, prices);

	if (isNaN(price)) {
		price = 0;
	}
	if (noNumberFormat) {
		return price;
	}
	return <NumberFormat displayType='text' thousandSeparator={true} prefix={prefix} value={price} />;
};

export const getLatFlowPrice = (vals, prices, prefix, noNumberFormat) => {
	let test = vals.tests;
	let quantity = vals.quantity;
	let price = lookupPrice(test, prices);
	price *= quantity;
	price = price.toFixed(2);

	if (isNaN(price)) {
		price = 0;
	}
	if (noNumberFormat) {
		return price;
	}
	return <NumberFormat displayType='text' thousandSeparator={true} prefix={prefix} value={price} />;
};

// TEST DATA
export const testFormData = {
	other: {
		vaxDoses: 'two',
		lastVaxDate: '05/01/2022',
		reason: 'arriving',
		testType: 'qPCR (Lab test)',
		testElement: 'Virus',
		testSite: 'home',
		testingSite: 'Spectrum',
		tests: ['Day2', 'Day5', 'Day8'],
		options: 'Posted_Same',
		departureDate: '',
		departureFlightNumber: '',
		arrivalDate: '05/01/2022',
		arrivalFlightNumber: '123465',
		generalDate: '',
		Fit2FlyAppointment: '',
		Day2Appointment: '',
		Day5Appointment: '',
		Day8Appointment: '',
		GeneralAppointment: '',
		price: '',
	},
	personal: {
		firstName: 'Paul',
		lastName: 'Lewis',
		email: 'paul@mediagrand.co.uk',
		mobile: '07843665464',
		passport: 'ABCD45645654',
		dateOfBirth: 'Mon Dec 27 2021 00:00:00 GMT+0000 (Greenwich Mean Time)',
		gender: 'male',
		ethnicity: 'Other ethnic group',
		residence: 'home',
		hotelName: '',
		addressLine1: '92 High Street',
		addressLine2: 'Weston',
		addressLine3: '',
		city: 'Bath',
		postCode: 'BA1 4DQ',
		country: 'GB',
		billingAddress: 'sameAsShipping',
		billingLine1: '',
		billingLine2: '',
		billingCity: '',
		billingPostCode: '',
		billingCountry: '',
	},
};

export const defaultFormData = {
	other: {
		vaxDoses: '',
		lastVaxDate: '',
		reason: '',
		testType: 'qPCR (Lab test)',
		testElement: 'Virus',
		testSite: '',
		testingSite: 'Spectrum',
		tests: [],
		options: false,
		departureDate: '',
		departureFlightNumber: '',
		arrivalDate: '',
		arrivalFlightNumber: '',
		generalDate: '',
		Fit2FlyAppointment: '',
		Day2Appointment: '',
		Day5Appointment: '',
		Day8Appointment: '',
		GeneralAppointment: '',
		price: '',
	},
	personal: {
		firstName: '',
		lastName: '',
		email: '',
		mobile: '',
		passport: '',
		dateOfBirth: '',
		gender: '',
		ethnicity: '',
		residence: 'home',
		hotelName: '',
		addressLine1: '',
		addressLine2: '',
		addressLine3: '',
		city: '',
		country: '',
		postCode: '',
		billingAddress: 'sameAsShipping',
		billingLine1: '',
		billingLine2: '',
		billingCity: '',
		billingPostCode: '',
		billingCountry: '',
	},
};

export const defaultLateralFlowFormData = {
	personal: {
		firstName: '',
		lastName: '',
		email: '',
		mobile: '',
		tests: 'Fit2Fly_LateralFlow',
		quantity: 0,
		residence: 'home',
		hotelName: '',
		addressLine1: '',
		addressLine2: '',
		addressLine3: '',
		city: '',
		country: '',
		postCode: '',
		billingAddress: 'sameAsShipping',
		billingLine1: '',
		billingLine2: '',
		billingCity: '',
		billingPostCode: '',
		billingCountry: '',
	},
};

export const formatDate = d => {
	if (d) {
		return format(new Date(d), 'dd/MM/yyyy');
	}
	return '';
};

export const formatDateTime = d => {
	if (d) {
		return format(new Date(d), 'dd/MM/yyyy HH:mm aa');
	}
	return '';
};

export const parseOption = option => {
	if (option === 'false' || option === false) {
		return 'Posted (Next Day Results)';
	}
	const split = option.split('_');
	if (split[0] === 'ClickCollect') {
		split[0] = 'Click & Collect';
	}
	if (split[1] === 'Next') {
		split[1] = 'Next Day Results';
	}
	if (split[1] === 'Same') {
		split[1] = 'Same Day Results';
	}
	return `${split[0]}, ${split[1]}`;
};

export const parseTests = vals => {
	const tests = [];
	{
		vals.tests.map(test => {
			if (test === 'Fit2Fly') {
				tests.push('Fit2Fly');
			}
			if (test === 'Day2') {
				tests.push('Day 2');
			}
			if (test === 'Day5') {
				tests.push('Day 5');
			}
			if (test === 'Day8') {
				tests.push('Day 8');
			}
			if (test === 'Fit2Fly+Day2') {
				tests.push('Fit2Fly');
				tests.push('Day 2');
			}
			if (test === 'General') {
				tests.push('General Testing');
			}
		});
	}
	return tests.join(', ');
};

export const parseLateralFlowTests = vals => {
	const split = vals.split('_');
	if (split[0] === 'Day2') {
		split[0] = 'Day 2';
	}
	return `${split[0]} Lateral Flow`;
};

export const getLateralFlowSuffix = (vals, quantity) => {
	const kit = parseLateralFlowTests(vals);
	if (quantity === 1) {
		return ` x ${kit} Test Kit`;
	}
	return ` x ${kit} Test Kits`;
};

export const parseReason = reason => {
	if (reason === 'leavingAndArriving') {
		return 'Leaving And Arriving';
	}

	return reason;
};

export const parseAppointment = vals => {
	const apps = [];
	const labels = [];

	if (vals.Fit2FlyAppointment !== '') {
		apps.push(formatDateTime(vals.Fit2FlyAppointment));
		labels.push('Fit2Fly');
	}
	if (vals.Day2Appointment !== '') {
		apps.push(formatDateTime(vals.Day2Appointment));
		labels.push('Day 2');
	}
	if (vals.Day5Appointment !== '') {
		apps.push(formatDateTime(vals.Day5Appointment));
		labels.push('Day 5');
	}
	if (vals.Day8Appointment !== '') {
		apps.push(formatDateTime(vals.Day8Appointment));
		labels.push('Day 8');
	}
	if (vals.GeneralAppointment !== '') {
		apps.push(formatDateTime(vals.GeneralAppointment));
		labels.push('General Testing');
	}

	return apps.map((app, i) => {
		return (
			<Fragment key={labels[i]}>
				<span className='reviewBold'>{labels[i]} Test Date &amp; Time:</span> <span>{app}</span>
				<br />
			</Fragment>
		);
	});
};

export const parseAddress = (type, vals) => {
	let address = '';
	if (type === 'shipping') {
		address += vals.addressLine1;

		if (vals.addressLine2 !== '') {
			address += ', ' + vals.addressLine2;
		}
		if (vals.addressLine3 !== '') {
			address += ', ' + vals.addressLine3;
		}
		address += ', ' + vals.city;
		address += ', ' + vals.postCode;
		address += ', ' + vals.country;
	}
	if (type === 'billing') {
		address += vals.billingLine1;

		if (vals.addressLine2 !== '') {
			address += ', ' + vals.billingLine2;
		}
		address += ', ' + vals.billingCity;
		address += ', ' + vals.billingPostCode;
		address += ', ' + vals.billingCountry;
	}

	return address;
};

export const clinicAddress = (
	<p>
		<strong>SM Labs Clinic</strong>
		<br />
		Holiday Inn Hotel
		<br />
		(Boardroom 3)
		<br />
		Wrights Lane
		<br />
		Kensington
		<br />
		London
		<br />
		W8 5SP
	</p>
);

export const travelInfo = (
	<div className='infoBox'>
		<p className='listHeader'>
			Travel advice changes daily, please follow the links below for up to date information.
		</p>
		<ul className='selectorInfo'>
			<li>
				For up to date travel advice from the UK Government&nbsp;
				<a href='https://www.gov.uk/foreign-travel-advice' target='_blank' className='selectorLink'>
					click here
				</a>
				.
			</li>
			<li>
				For up to date advice from the UK Government about travelling with children&nbsp;
				<a
					href='https://www.gov.uk/guidance/travel-to-england-from-another-country-during-coronavirus-covid-19#travelling-with-children'
					target='_blank'
					className='selectorLink'
				>
					click here
				</a>
				.
			</li>
		</ul>

		<br />

		<p className='listHeader'>Cut-off times to receive test results:</p>
		<ul className='selectorInfo'>
			<li>
				Home (posted to home or hotel).
				<ul className='selectorInfo'>
					<li>
						Standard - your result will be available next day (after your test arrives at lab).
					</li>
				</ul>
			</li>
			<li>
				{clickCollect} from our clinic then drop back.
				<ul className='selectorInfo'>
					<li>Clinic opening hours: 11am - 6pm.</li>
					<li>Same day - if dropped back by 4pm, otherwise next day</li>
					<li>Emergency - if dropped back by 4pm, otherwise next day</li>
				</ul>
			</li>
			<li>
				{inclinic} test at our clinic.
				<ul className='selectorInfo'>
					<li>Clinic opening hours: 11am - 6pm.</li>
					<li>Same day - if dropped back by 4pm, otherwise next day</li>
					<li>Emergency - if dropped back by 4pm, otherwise next day</li>
				</ul>
			</li>
		</ul>
	</div>
);
