import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import HeaderSMLabs from '../wrappers/header/HeaderSMLabs';
import FooterSMLabs from '../wrappers/footer/FooterSMLabs';

const LayoutSMLabs = ({
	children,
	headerContainerClass,
	headerTop,
	headerPaddingClass,
	headerPositionClass,
}) => {
	return (
		<Fragment>
			<HeaderSMLabs
				layout={headerContainerClass}
				top={headerTop}
				headerPaddingClass={headerPaddingClass}
				headerPositionClass={headerPositionClass}
			/>
			{children}
			<FooterSMLabs
				backgroundColorClass='bg-gray'
				spaceTopClass='pt-100'
				spaceBottomClass='pb-70'
			/>
		</Fragment>
	);
};

LayoutSMLabs.propTypes = {
	children: PropTypes.any,
	headerContainerClass: PropTypes.string,
	headerPaddingClass: PropTypes.string,
	headerPositionClass: PropTypes.string,
	headerTop: PropTypes.string,
};

export default LayoutSMLabs;
