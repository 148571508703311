import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import LFCheckoutForm from '../../components/booking-form/LFCheckoutForm';

const stripePromise = loadStripe(
	process.env.NODE_ENV === 'production'
		? process.env.REACT_APP_STRIPE_LIVE_PK
		: process.env.REACT_APP_STRIPE_TEST_PK,
);

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PK);

const LFCheckout = ({formValues, handleStripeOrder, prices}) => {
	const history = useHistory();

	if (formValues.personal.email === '') {
		history.push('/lateral-flow-form');
	}

	const [clientSecret, setClientSecret] = useState('');

	useEffect(() => {
		fetch(`/getLatFlowClient.php`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				items: formValues.personal.tests,
				quantity: formValues.personal.quantity,
			}),
		})
			.then(res => res.json())
			.then(data => setClientSecret(data.clientSecret));
	}, []);

	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	};

	if (clientSecret === '') {
		return <div style={{height: '440px'}}></div>;
	}

	return (
		<>
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<LFCheckoutForm
						clientSecret={clientSecret}
						handleStripeOrder={handleStripeOrder}
						formValues={formValues}
						prices={prices}
					/>
				</Elements>
			)}
		</>
	);
};

LFCheckout.propTypes = {
	formValues: PropTypes.object,
	handleStripeOrder: PropTypes.func,
	prices: PropTypes.object.isRequired,
};
export default LFCheckout;
