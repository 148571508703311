import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById('booking-form'),
);

serviceWorker.unregister();
