import PropTypes from 'prop-types';
import React from 'react';

const NavMenu = ({menuWhiteClass, sidebarMenu}) => {
	return (
		<div
			className={` ${
				sidebarMenu ? 'sidebar-menu' : `main-menu ${menuWhiteClass ? menuWhiteClass : ''}`
			} `}
		>
			<nav>
				<ul>
					<li>
						<a href='https://testkitexpress.co.uk/' target='_self'>
							Home
						</a>
					</li>
					<li>
						<a href='https://testkitexpress.co.uk/knowledge/' target='_self'>
							Knowledge
						</a>
					</li>
					<li>
						<a href='https://testkitexpress.co.uk/covid-tests/' target='_self'>
							Covid Tests
						</a>
					</li>
					<li>
						<a href='https://testkitexpress.co.uk/shop/' target='_self'>
							Shop All Tests
						</a>
					</li>
					<li>
						<a href='https://staging79.testkitexpress.co.uk/managed-solutions/' target='_self'>
							Managed Solutions
						</a>
					</li>
					<li>
						<a href='https://testkitexpress.co.uk/about-us/' target='_self'>
							About Us
						</a>
					</li>
					<li>
						<a href='https://testkitexpress.co.uk/contact-us/' target='_self'>
							Contact
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
};

NavMenu.propTypes = {
	menuWhiteClass: PropTypes.string,
	sidebarMenu: PropTypes.bool,
	strings: PropTypes.object,
};

export default NavMenu;
