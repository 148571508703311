import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {animateScroll} from 'react-scroll';
import FooterCopyright from '../../components/footer/FooterCopyright';

const FooterSMLabs = ({containerClass}) => {
	const [scroll, setScroll] = useState(0);
	const [top, setTop] = useState(0);

	useEffect(() => {
		setTop(100);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		animateScroll.scrollToTop();
	};

	const handleScroll = () => {
		setScroll(window.scrollY);
	};

	return (
		<>
			<footer className={`footer-area  et_pb_section2`}>
				<div className={`container-md`}>
					<div className='row '>
						<div className='col-lg-4 col-md-4 '>
							<div>
								<h4 className='widget-title'>CONTACT DETAILS</h4>
							</div>
							<div className='footLinks'>
								<h4 className='coname-title'>Test Kit Express</h4>
								<p>
									<a href='tel:01749684085'>+44 (0) 1749 684085</a>
								</p>
								<p>
									<a href='mailto:enquiries@testkitexpress.co.uk'>enquiries@testkitexpress.co.uk</a>
								</p>
								<img
									src='https://testkitexpress.co.uk/wp-content/uploads/2021/11/TestKit-logo.png'
									style={{width: '200px'}}
								/>
							</div>{' '}
						</div>

						<div className='col-lg-4 col-md-4'>
							<div>
								<div>
									<h4 className='widget-title'>Page Links</h4>
								</div>
								<nav className='footer-menu-1-container' aria-label='Page Links'>
									<ul id='menu-footer-menu-1' className='menu'>
										<li>
											<a href='https://testkitexpress.co.uk/'>Home</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/knowledge/' aria-current='page'>
												Knowledge
											</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/covid-tests/' aria-current='page'>
												Covid Tests
											</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/shop/'>Shop All Tests</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/managed-solutions/'>
												Managed Solutions
											</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/about-us/' target='_self'>
												About Us
											</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/contact-us/'>Contact</a>
										</li>
									</ul>
								</nav>
							</div>{' '}
						</div>
						<div className='col-lg-4 col-md-4'>
							<div id='nav_menu-2' className='footer-widget mb-30 widget_nav_menu'>
								<div className='footer-title'>
									<h2 className='widget-title'>Useful Links</h2>
								</div>
								<nav className='menu-footer-menu-1-container' aria-label='Useful Links'>
									<ul id='menu-footer-menu-1' className='menu'>
										<li>
											<a href='https://testkitexpress.co.uk/my-account/'>Your Account</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/faq/'>Frequently Asked Questions</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/wooden-spoon-charity/'>
												Wooden Spoon Charity Partner
											</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/refund-policy-and-returns/'>
												Refund Policy and Returns
											</a>
										</li>
										<li>
											<a href='https://testkitexpress.co.uk/privacy-policy/'>Privacy Policy</a>
										</li>
									</ul>
								</nav>
							</div>{' '}
						</div>
					</div>
				</div>

				<div className={`${containerClass ? containerClass : 'container-md'} pt-70`}>
					<div className='row'>
						<div className='col-xl-12 col-sm-12'>
							<FooterCopyright />
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

FooterSMLabs.propTypes = {
	backgroundColorClass: PropTypes.string,
	containerClass: PropTypes.string,
	extraFooterClass: PropTypes.string,
	sideMenu: PropTypes.bool,
	spaceBottomClass: PropTypes.string,
	spaceTopClass: PropTypes.string,
	spaceLeftClass: PropTypes.string,
	spaceRightClass: PropTypes.string,
};

export default FooterSMLabs;
