import PropTypes from 'prop-types';
import React from 'react';

const FooterCopyright = ({spaceBottomClass, colorClass}) => {
	return (
		<div
			className={`copyright ${spaceBottomClass ? spaceBottomClass : ''} ${
				colorClass ? colorClass : ''
			}`}
		>
			<p>&copy; {new Date().getFullYear()} Test Kit Express</p>
			<p>Website by Frecom</p>
		</div>
	);
};

FooterCopyright.propTypes = {
	spaceBottomClass: PropTypes.string,
	colorClass: PropTypes.string,
};

export default FooterCopyright;
