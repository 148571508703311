import PropTypes from 'prop-types';

const FormWarning = ({copy}) => {
	return (
		<div className='formWarning'>
			<p>{copy}</p>
		</div>
	);
};

FormWarning.propTypes = {
	copy: PropTypes.string,
};
export default FormWarning;
