import React, {Suspense, Fragment, useState, useEffect} from 'react';
import {Switch, Route, Redirect, withRouter, useHistory} from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import ScrollToTop from './helpers/scroll-top';
import LayoutSMLabs from './layouts/LayoutSMLabs';
// import BookingForm from './pages/BookingForm';
// import KitSelector from './pages/KitSelector';
// import FormOtherDetails from './pages/FormOtherDetails';
// import FormPersonalDetails from './pages/FormPersonalDetails';
// import ReviewOrder from './pages/ReviewOrder';
// import Checkout from './pages/Checkout';
// import ShippingAddress from './pages/ShippingAddress';
// import Confirmation from './pages/Confirmation';
import {defaultFormData, testFormData, defaultLateralFlowFormData} from './helpers/testKitOptions';
import ReactGA from 'react-ga4';
import FormLateraFlow from './pages/lateralflow/FormLateraFlow';
import LFReviewOrder from './pages/lateralflow/LFReviewOrder';
import LFCheckout from './pages/lateralflow/LFCheckout';
import LFShippingAddress from './pages/lateralflow/LFShippingAddress';
import LFConfirmation from './pages/lateralflow/LFConfirmation';

const App = () => {
	const testing = false;
	const useData = testing ? testFormData : defaultFormData;

	const [formData, setFormData] = useState(useData);
	const [prices, setPrices] = useState({});
	const [orderNumber, setOrderNumber] = useState('');
	const [latFlowFormData, setLatFlowFormData] = useState(defaultLateralFlowFormData);

	ReactGA.initialize('G-LHW011JM5D');
	ReactGA.send('pageview');

	const getPriceList = () => {
		fetch(`/pricelist.json`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		})
			.then(response => {
				return response.json();
			})
			.then(data => {
				setPrices(data.prices);
			})
			.catch(err => {
				console.log('err', err);
			});
	};

	useEffect(() => {
		if (Object.keys(prices).length === 0) {
			getPriceList();
		}
	}, [prices]);

	let history = useHistory();

	const handleFormNext = (category, data) => {
		// console.log('handleFormNext', category, data);

		if (category === 'selector') {
			setFormData({
				...formData,
				other: data,
			});
			history.push('/general-information-form');
		}
		if (category === 'other') {
			setFormData({
				...formData,
				other: data,
			});
			history.push('/personal-information-form');
		}
		if (category === 'personal') {
			setFormData({
				...formData,
				personal: data,
			});
			history.push('/shipping-address');
		}
		if (category === 'shipping') {
			setFormData({
				...formData,
				personal: data,
			});
			history.push('/review-order');
		}
	};

	const handleFormBack = () => {
		history.goBack();
	};

	const handleShowForm = () => {
		setFormData(defaultFormData);
		history.push('/general-information-form');
	};

	const handleShowKitSelector = () => {
		history.push('/kit-selector');
	};

	const handleStripeOrder = orderNumber => {
		setOrderNumber(orderNumber);
		history.push('/order-confirmation');
	};

	const handleShowLateralFlowForm = data => {
		if (data && data.tests) {
			setLatFlowFormData({
				...latFlowFormData,
				personal: data,
			});
		} else {
			setLatFlowFormData(defaultLateralFlowFormData);
		}

		history.push('/lateral-flow-form');
	};

	const handleLFFormNext = (category, data) => {
		// console.log('handleLFFormNext', category, data);

		if (category === 'personal') {
			setLatFlowFormData({
				...latFlowFormData,
				personal: data,
			});
			history.push('/lf-shipping-address');
		}
		if (category === 'shipping') {
			setLatFlowFormData({
				...latFlowFormData,
				personal: data,
			});
			history.push('/lf-review-order');
		}
	};

	const handleLatFlowStripeOrder = orderNumber => {
		setOrderNumber(orderNumber);
		history.push('/lf-order-confirmation');
	};

	if (prices.length === 0) {
		return (
			<div className='flone-preloader-wrapper'>
				<div className='flone-preloader'>
					<span></span>
					<span></span>
				</div>
			</div>
		);
	}

	return (
		<ScrollToTop>
			<Suspense
				fallback={
					<div className='flone-preloader-wrapper'>
						<div className='flone-preloader'>
							<span></span>
							<span></span>
						</div>
					</div>
				}
			>
				<Fragment>
					<LayoutSMLabs
						headerContainerClass='container-fluid'
						headerPaddingClass='header-padding-1'
					>
						<div className='booking-form pt-50 pb-50'>
							<div className='container-lg'>
								<div className='row'>
									<div className='col-lg-9 ml-auto mr-auto'>
										<div className='login-register-wrapper'>
											<Switch>
												{/* <Route path={`/booking-form`}>
													<>
														<MetaTags>
															<title>Booking Form</title>
															<meta
																name='description'
																content='Test Kit Express - Covid Lateral Flow Test Booking Form for Fit2Fly Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<BookingForm
															skipSelector={handleShowForm}
															showKitSelector={handleShowKitSelector}
															showLFForm={handleShowLateralFlowForm}
														/>
													</>
												</Route>
												<Route path={`/kit-selector`}>
													<>
														<MetaTags>
															<title>Test Kit Selector</title>
															<meta
																name='description'
																content='Test Kit Express - Test Kit Selector for Fit2Fly and Day 2 Rapid Antigen Testing for Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<KitSelector
															category='selector'
															initialFormValues={latFlowFormData}
															formNext={handleFormNext}
															formBack={handleFormBack}
															showLatFlowForm={handleShowLateralFlowForm}
															prices={prices}
														/>
													</>
												</Route> */}
												{/* <Route path={`/general-information-form`}>
													<>
														<MetaTags>
															<title>General Information</title>
															<meta
																name='description'
																content='Test Kit Express - General Information Form for Fit2Fly and Day 2 Rapid Antigen Testing for Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<FormOtherDetails
															category='other'
															initialFormValues={formData}
															onClickFormNext={handleFormNext}
															onClickFormBack={handleShowKitSelector}
															prices={prices}
														/>
													</>
												</Route>

												<Route path={`/personal-information-form`}>
													<>
														<MetaTags>
															<title>Personal Information</title>
															<meta
																name='description'
																content='Test Kit Express - Personal Information Form for Fit2Fly and Day 2 Rapid Antigen Testing for Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<FormPersonalDetails
															category='personal'
															initialFormValues={formData}
															onClickFormNext={handleFormNext}
															onClickFormBack={handleFormBack}
														/>
													</>
												</Route>
												<Route path={`/shipping-address`}>
													<>
														<MetaTags>
															<title>Address</title>
															<meta
																name='description'
																content='Test Kit Express - Shipping Address Form for Fit2Fly and Day 2 Rapid Antigen Testing for Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<ShippingAddress
															category='shipping'
															initialFormValues={formData}
															onClickFormNext={handleFormNext}
															onClickFormBack={handleFormBack}
														/>
													</>
												</Route>
												<Route path={`/review-order`}>
													<>
														<MetaTags>
															<title>Review Your Order</title>
															<meta
																name='description'
																content='Test Kit Express - Review Your PCR Test Kit Purchase Order for Fit2Fly and Day 2 Rapid Antigen Testing for Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<ReviewOrder
															formValues={formData}
															onClickFormBack={handleFormBack}
															prices={prices}
														/>
													</>
												</Route>
												<Route path={`/checkout`}>
													<>
														<MetaTags>
															<title>Checkout</title>
															<meta name='description' content='Test Kit Express Checkout' />
														</MetaTags>
														<Checkout
															formValues={formData}
															prices={prices}
															handleStripeOrder={handleStripeOrder}
														/>
													</>
												</Route>
												<Route path={`/order-confirmation`}>
													<>
														<MetaTags>
															<title>Order Confirmation</title>
															<meta
																name='description'
																content='Test Kit Express Order Confirmation'
															/>
														</MetaTags>
														{orderNumber !== '' && (
															<Confirmation
																prices={prices}
																formValues={formData}
																orderNumber={orderNumber}
															/>
														)}
														{orderNumber === '' && <Redirect to={'/general-information-form'} />}
													</>
												</Route>*/}

												<Route path={`/lateral-flow-form`}>
													<>
														<MetaTags>
															<title>General Information</title>
															<meta
																name='description'
																content='Test Kit Express - Lateral Flow Booking Form for Fit2Fly and Day 2 Rapid Antigen Testing for Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<FormLateraFlow
															category='personal'
															initialFormValues={latFlowFormData}
															onClickFormNext={handleLFFormNext}
															onClickFormBack={handleFormBack}
															prices={prices}
														/>
													</>
												</Route>
												<Route path={`/lf-shipping-address`}>
													<>
														<MetaTags>
															<title>Address</title>
															<meta
																name='description'
																content='Test Kit Express - Shipping Address Form for Fit2Fly and Day 2 Rapid Antigen Testing for Leaving and Arriving in the UK'
															/>
														</MetaTags>
														<LFShippingAddress
															category='shipping'
															initialFormValues={latFlowFormData}
															onClickFormNext={handleLFFormNext}
														/>
													</>
												</Route>
												<Route path={`/lf-review-order`}>
													<>
														<MetaTags>
															<title>Review Your Order</title>
															<meta name='description' content='Test Kit Express Review Order' />
														</MetaTags>
														<LFReviewOrder formValues={latFlowFormData} prices={prices} />
													</>
												</Route>
												<Route path={`/lf-checkout`}>
													<>
														<MetaTags>
															<title>Checkout</title>
															<meta name='description' content='Test Kit Express Checkout' />
														</MetaTags>
														<LFCheckout
															formValues={latFlowFormData}
															prices={prices}
															handleStripeOrder={handleLatFlowStripeOrder}
														/>
													</>
												</Route>
												<Route path={`/lf-order-confirmation`}>
													<>
														<MetaTags>
															<title>Order Confirmation</title>
															<meta
																name='description'
																content='Test Kit Express Order Confirmation'
															/>
														</MetaTags>
														{orderNumber !== '' && (
															<LFConfirmation
																prices={prices}
																formValues={latFlowFormData}
																orderNumber={orderNumber}
															/>
														)}
														{orderNumber === '' && <Redirect to={'/lateral-flow-form'} />}
													</>
												</Route>

												<Route path={`/`}>
													<Redirect to={'/lateral-flow-form'} />
												</Route>
											</Switch>
										</div>
									</div>
								</div>
							</div>
						</div>
					</LayoutSMLabs>
				</Fragment>
			</Suspense>
		</ScrollToTop>
	);
};

export default withRouter(App);
