import React from 'react';
import PropTypes from 'prop-types';

const AllFormError = ({touched, errors}) => {
	if (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) {
		let match = false;
		const touchedKeys = [];
		const errorKeys = [];

		Object.keys(touched).map(t => {
			if (t) {
				touchedKeys.push(t);
			}
		});
		Object.keys(errors).map(e => {
			errorKeys.push(e);
		});

		// console.log('%c', 'color: #7f7700', touchedKeys);
		// console.log('%c', 'color: #00ff88', errorKeys);

		errorKeys.map(ek => {
			if (!match) {
				match = touchedKeys.includes(ek);
				// console.log('%c%s', 'color: #f279ca', match);
			}
		});

		if (!match) {
			return null;
		}

		return (
			<>
				{match && (
					<p className='allformerror'>There are fields on the form that require your attention</p>
				)}
			</>
		);
	}
	return null;
};
AllFormError.propTypes = {
	touched: PropTypes.object,
	errors: PropTypes.object,
};
export default AllFormError;
