import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {ErrorMessage, Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import SectionTitle from '../../components/section-title/SectionTitle';
import {renderError, getLatFlowPrice} from '../../helpers/testKitOptions';
import AllFormError from '../../components/booking-form/AllFormError';
import ProgressBarSteps from '../../components/booking-form/ProgressBarSteps';

const FormLateraFlow = ({
	onClickFormNext,
	onClickFormBack,
	category,
	initialFormValues,
	prices,
}) => {
	return (
		<>
			<Formik
				initialValues={initialFormValues !== null ? initialFormValues[category] : {}}
				validationSchema={Yup.object({
					firstName: Yup.string().required('please enter your first name'),
					lastName: Yup.string().required('please enter your last name'),
					email: Yup.string().email('invalid email address').required('please enter your email'),
					mobile: Yup.string().phone().required('please enter your mobile phone number'),
					// tests: Yup.string().required('please select a test kit'),
					quantity: Yup.number()
						.min(1)
						.max(50)
						.required('Please enter a quantity between 1 and 50'),
				})}
				onSubmit={(values, {setSubmitting}) => {
					setSubmitting(false);
					values.tests = 'Fit2Fly_LateralFlow';
					onClickFormNext(category, values);
				}}
			>
				{props => (
					<Form>
						{/* <h3 className='mb-40' style={{textAlign: 'center'}}>
							Order Your Fit2Fly Lateral Flow Test
						</h3> */}
						<SectionTitle titleText='Personal Details' positionClass='text-center' />
						<label htmlFor='firstName'>First Name</label> <span className='required'>*</span>
						<Field name='firstName' type='text' />
						<ErrorMessage name='firstName' render={renderError} />
						<label htmlFor='lastName'>Last Name</label> <span className='required'>*</span>
						<Field name='lastName' type='text' />
						<ErrorMessage name='lastName' render={renderError} />
						<label htmlFor='email'>Email Address</label> <span className='required'>*</span>
						<Field name='email' type='email' />
						<ErrorMessage name='email' render={renderError} />
						<label htmlFor='mobile'>Mobile Number</label> <span className='required'>*</span>
						<Field name='mobile' type='text' />
						<ErrorMessage name='mobile' render={renderError} />
						<SectionTitle titleText='Kit Details' positionClass='text-center' spaceClass='pt-25' />
						<label htmlFor='tests'>Test Kit</label>
						<Field name='tests' type='text' readOnly value='Fit2Fly Lateral Flow' />
						{/* <label htmlFor='tests'>Test Kit</label> <span className='required'>*</span>
						<Field name='tests' as='select' className='custom-select'>
							<option value='' defaultValue disabled hidden>
								Select...
							</option>
							<option value='Day2_LateralFlow'>Day 2 Lateral Flow</option>
							<option value='Fit2Fly_LateralFlow'>Fit2Fly Lateral Flow</option>
						</Field>
						<ErrorMessage name='tests' render={renderError} /> */}
						<div className='priceBox'>{getLatFlowPrice(props.values, prices, 'Total: £')}</div>
						<h4>£{prices.Day2_LateralFlow} each</h4>
						<label htmlFor='quantity'>Quantity</label> <span className='required'>*</span>
						<Field name='quantity' type='number' />
						<ErrorMessage name='quantity' render={renderError} />
						<AllFormError touched={props.touched} errors={props.errors} />
						<div className='singleBtn'>
							{/* <button type='button' className='next-btn' onClick={onClickFormBack}>
								Back
							</button> */}
							<button type='submit' className='next-btn'>
								Next
							</button>
						</div>
						<ProgressBarSteps percentile={0} numSteps={5} />
					</Form>
				)}
			</Formik>
		</>
	);
};

FormLateraFlow.propTypes = {
	onClickFormNext: PropTypes.func,
	onClickFormBack: PropTypes.func,
	category: PropTypes.string,
	initialFormValues: PropTypes.object,
	prices: PropTypes.object.isRequired,
};

export default memo(FormLateraFlow);
