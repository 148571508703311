import PropTypes from 'prop-types';
import {Link, useHistory} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {getLatFlowPrice, parseAddress, getLateralFlowSuffix} from '../../helpers/testKitOptions';
import SectionTitle from '../../components/section-title/SectionTitle';
import ProgressBarSteps from '../../components/booking-form/ProgressBarSteps';
import FormWarning from '../../components/booking-form/FormWarning';

const LFConfirmation = ({formValues, prices, orderNumber}) => {
	const history = useHistory();

	if (formValues.personal.email === '') {
		history.push('/lateral-flow-form');
	}
	return (
		<>
			<SectionTitle titleText='Order Confirmation' positionClass='text-center' />

			<h2>We've received your order</h2>
			<h4>
				Order number <strong>{orderNumber}</strong>
			</h4>
			<p>
				A copy of your receipt has been sent to <strong>{formValues.personal.email}</strong>
			</p>
			<FormWarning
				copy={`If the email doesn't appear in your inbox, please check your spam/junk folder.`}
			/>

			<div className='confirm-box'>
				<h4>Delivery Details</h4>

				<p>
					{formValues.personal.firstName + ' ' + formValues.personal.lastName}
					<br />
					{formValues.personal.mobile}
				</p>
				<h4>Shipping Address</h4>
				<p>
					{formValues.personal.residence === 'hotel' && (
						<>
							{formValues.personal.hotelName}
							<br />
						</>
					)}

					{parseAddress('shipping', formValues.personal)}
				</p>
			</div>

			<div className='confirm-box'>
				<h4>Order Summary</h4>
				<NumberFormat
					displayType='text'
					thousandSeparator={true}
					suffix={getLateralFlowSuffix(formValues.personal.tests, formValues.personal.quantity)}
					value={formValues.personal.quantity}
				/>
			</div>

			<div className='confirm-box'>
				<h4 className='confirm-box-price'>
					{getLatFlowPrice(formValues.personal, prices, 'Total: £')}
				</h4>
			</div>

			<div className='confirm-box'>
				<h4>Billing Address</h4>
				{formValues.personal.billingAddress === 'useDifferent' ? (
					<p>{parseAddress('billing', formValues.personal)}</p>
				) : (
					<p>Same as shipping address</p>
				)}
			</div>

			<div className='doubleBtns'>
				<Link to={process.env.PUBLIC_URL + '/lateral-flow-form'} className='next-btn'>
					Back to Home
				</Link>
			</div>
			<ProgressBarSteps percentile={100} numSteps={5} />
		</>
	);
};

LFConfirmation.propTypes = {
	formValues: PropTypes.object,
	prices: PropTypes.object.isRequired,
	orderNumber: PropTypes.string,
};
export default LFConfirmation;
