import React from 'react';

const IconGroup = ({}) => {
	const triggerMobileMenu = () => {
		const offcanvasMobileMenu = document.querySelector('#offcanvas-mobile-menu');
		offcanvasMobileMenu.classList.add('active');
	};

	return (
		<div className={`header-right-wrap `}>
			<div className='same-style mobile-off-canvas d-block d-lg-none'>
				<div className='mob-menu'>
					<a
						href='#nav'
						className='mob-menu-reveal'
						id='mobile-menu-close-trigger'
						onClick={() => triggerMobileMenu()}
					>
						<span></span>
						<span></span>
						<span></span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default IconGroup;
