import PropTypes from 'prop-types';
import React from 'react';

const MobileNavMenu = () => {
	return (
		<nav className='offcanvas-navigation' id='offcanvas-navigation'>
			<ul>
				<li>
					<a href='https://testkitexpress.co.uk/' target='_self'>
						Home
					</a>
				</li>
				<li>
					<a
						href='https://testkitexpress.co.uk/flowflex-sars-cov-2-antigen-rapid-test-self-testing/'
						target='_self'
					>
						Rapid Antigen Tests
					</a>
				</li>
				<li>
					<a href='https://testkitexpress.co.uk/order-now/' target='_self'>
						Order Now
					</a>
				</li>
				<li>
					<a href='https://testkitexpress.co.uk/frequently-asked-questions/' target='_self'>
						FAQs
					</a>
				</li>
				<li>
					<a href='https://testkitexpress.co.uk/charity-friends/' target='_self'>
						Charity
					</a>
				</li>
				<li>
					<a href='https://testkitexpress.co.uk/contact-us/' target='_self'>
						Contact
					</a>
				</li>
			</ul>
		</nav>
	);
};

MobileNavMenu.propTypes = {
	strings: PropTypes.object,
};

export default MobileNavMenu;
