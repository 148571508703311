import PropTypes from 'prop-types';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import SectionTitle from '../../components/section-title/SectionTitle';
import {getLatFlowPrice, getLateralFlowSuffix} from '../../helpers/testKitOptions';
import ProgressBarSteps from '../../components/booking-form/ProgressBarSteps';

const LFReviewOrder = ({formValues, prices}) => {
	const history = useHistory();

	if (formValues.personal.email === '') {
		history.push('/lateral-flow-form');
	}

	return (
		<div>
			<SectionTitle titleText='Review Your Order' positionClass='text-center' spaceClass='pb-25' />

			<div className='row'>
				<div className='col-12'>
					<div className='confirm-box'>
						<h4>Personal Details</h4>
						<p>
							<span className='reviewBold'>Name:</span>{' '}
							<span className='capcase'>
								{formValues.personal.firstName} {formValues.personal.lastName}
							</span>
							<br />
							<span className='reviewBold'>Email Address:</span> {formValues.personal.email}
							<br />
							<span className='reviewBold'> Mobile Number:</span> {formValues.personal.mobile}
						</p>
					</div>

					<div className='confirm-box'>
						<h4>Shipping Address</h4>
						<p>
							{formValues.personal.residence === 'hotel' && (
								<>
									<span className='capcase'>{formValues.personal.hotelName}</span>
									<br />
								</>
							)}
							<span className='capcase'>{formValues.personal.addressLine1}</span>
							<br />
							{formValues.personal.addressLine2 !== '' && (
								<>
									<span className='capcase'>{formValues.personal.addressLine2}</span>
									<br />
								</>
							)}
							{formValues.personal.addressLine3 !== '' && (
								<>
									<span className='capcase'>{formValues.personal.addressLine3}</span>
									<br />
								</>
							)}
							<span className='capcase'>{formValues.personal.city}</span>
							<br />
							{formValues.personal.postCode}
							<br />
							{formValues.personal.country}
						</p>
					</div>

					<div className='confirm-box'>
						<h4>Billing Address</h4>
						{formValues.personal.billingAddress === 'useDifferent' ? (
							<p>
								<span className='capcase'>{formValues.personal.billingLine1}</span>
								<br />
								{formValues.personal.billingLine2 !== '' && (
									<>
										<span className='capcase'>{formValues.personal.billingLine2}</span>
										<br />
									</>
								)}
								<span className='capcase'>{formValues.personal.billingCity}</span>
								<br />
								{formValues.personal.billingPostCode}
								<br />
								{formValues.personal.billingCountry}
							</p>
						) : (
							<p>Same as shipping address</p>
						)}
					</div>
				</div>
			</div>

			<div className='mt-10'>
				<div className='priceBox' style={{display: 'flex', justifyContent: 'space-between'}}>
					<NumberFormat
						displayType='text'
						thousandSeparator={true}
						suffix={getLateralFlowSuffix(formValues.personal.tests, formValues.personal.quantity)}
						value={formValues.personal.quantity}
					/>
					<span>{getLatFlowPrice(formValues.personal, prices, 'Total: £')}</span>
				</div>

				<div className='doubleBtns'>
					<Link to={process.env.PUBLIC_URL + '/lf-shipping-address'} className='next-btn'>
						Back
					</Link>
					<Link to={process.env.PUBLIC_URL + '/lf-checkout'} className='next-btn'>
						Proceed to Checkout
					</Link>
				</div>
				<ProgressBarSteps percentile={50} numSteps={5} />
			</div>
		</div>
	);
};

LFReviewOrder.propTypes = {
	formValues: PropTypes.object,
	prices: PropTypes.object.isRequired,
};
export default LFReviewOrder;
