import PropTypes from 'prop-types';
import React from 'react';
import 'react-step-progress-bar/styles.css';
import {ProgressBar, Step} from 'react-step-progress-bar';

const ProgressBarSteps = ({percentile, numSteps = 5}) => {
	return (
		<div
			className='mt-40 mb-40 '
			style={{
				padding: '20px 10px',
			}}
		>
			{numSteps === 5 && (
				<ProgressBar percent={percentile} filledBackground='#0288d1' height={10}>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
				</ProgressBar>
			)}
			{numSteps === 6 && (
				<ProgressBar percent={percentile} filledBackground='#0288d1' height={10}>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
					<Step>
						{({accomplished, index}) => (
							<div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>
								{index + 1}
							</div>
						)}
					</Step>
				</ProgressBar>
			)}
		</div>
	);
};

ProgressBarSteps.propTypes = {
	percentile: PropTypes.number,
	numSteps: PropTypes.number,
};
export default ProgressBarSteps;
